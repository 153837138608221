<template>
	<div class="printMain body-bg" v-loading="loading_load">
		<!-- 导入excel -->
		<!-- <el-dialog :title="$t('i18nn_17a941b7c4c108fe')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px" top="0"> -->
		<!-- <div class="top_action no_print"><el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button></div> -->
		<div class="printer-logo"><img :src="$store.state.sysShow.logoInfo.logo" width="80" height="auto" /></div>
		<div style="">
			<div class="printer_con">
				<!-- <h1 class="printer_tit">
					HYTX Warehouse {{priterData.whNo}} Container Packing List<br />
					Container#<span>{{ priterData.packingNo }}</span><br />
					Customer:<span>{{ priterData.cusName }}</span>
				</h1> -->
				<h1 class="printer_tit">
					<!-- <div>
						<barcode :code="priterData.receiptNo"></barcode>
					</div> -->
					<div>
						Warehouse Container Packing List
					</div>
					<!-- <div style="font-size: 12pt; text-align: right;">
						<div>Warehouse#&nbsp;{{priterData.whNo}}</div>
						<div>Container#&nbsp;<strong>{{priterData.packingNo}}</strong></div>
						<div>Date#&nbsp;{{priterData.commitDate}}</div>
					</div> -->
				</h1>

				<div style="display: flex;justify-content: space-between; align-items: center; ">

					<barcode :code="priterData.receiptNo" :options="{height:50}"></barcode>
					<div style="font-size: 12pt; text-align: right; word-break: break-all;white-space:normal; ">
						<div style="margin:5px 0;">Warehouse#&nbsp;{{priterData.whNo}}</div>
						<div style="margin:5px 0;">Container#&nbsp;<strong>{{priterData.packingNo}}</strong></div>
						<div style="margin:5px 0;">Date#&nbsp;{{priterData.commitDate}}</div>
						<!-- <div>{{priterData.cusName}}</div> -->
						<div style="margin:5px 0;">Customer#&nbsp;<span>{{priterData.cusName}}</span></div>
					</div>
				</div>


				<!-- <h2 class="printer_tit2">
          <div class="titleItem">
            <span>{{$t('b7a10fe4e9528362')}}</span>：
            <strong>{{ priterData.packingNo }}</strong>
          </div>
          <div class="titleItem">
            <span>{{$t('i18nn_643e08ba53f72f39')}}</span>：
            <strong>{{ priterData.packingTypeName }}</strong>
          </div>
        </h2> -->

				<div class="titleItem">
					<span>Total CTN#</span>
					<strong>{{ priterData.totalCtn }}</strong>
				</div>
				<div class="titleItem">
					<span>Total PCS#</span>
					<strong>{{ priterData.totalPcs }}</strong>
				</div>
				<div class="titleItem">
					<span>Total Pallet#</span>
					<strong>{{ priterData.totalPallet }}</strong>
				</div>


				<table class="simTable printerTable">
					<tbody class="text_center">
						<!-- <tr> -->
							<!-- <th></th> -->
							<!-- <th></th> -->
							<!-- <th></th> -->
							<!-- <th colspan="1" class="gray_bg">Quantity</th> -->
							<!-- <th></th> -->
							<!-- <th class="gray_bg">Actually Received</th> -->
							<!-- <th></th> -->
							<!-- <th></th> -->
							<!-- <th></th> -->
							<!-- <th></th> -->
						<!-- </tr> -->
						<!-- <tr class="gray_bg" v-for="(item, index) in 500" :key="index"> -->
						<tr class="gray_bg">
							<!-- <th width="50">NEW</th> -->
							<!-- <th width="100">Mark & No.</th> -->
							<!-- <th width="100">Despcription</th> -->
							<th width="200">SKU</th>
							<!-- <th width="50">PCS</th> -->
							<th width="200">Quantity</th>
							<!-- <th width="50">PCS/CTN</th> -->
							<th width="200">Actually Received<br />PCS</th>
							<th width="150">SKU(l*w*h)<br />(Weight)</th>
							<!-- <th width="100">SKU(Weight)</th> -->
							<th width="50">Total Pallets</th>
							<th width="100">Location</th>
							<!-- <th width="100">Remark</th> -->
						</tr>
					</tbody>
					<tbody v-if="priterData.records" class="text_center text_small">
						<tr v-for="(item, index) in priterData.records" :key="index">
							<!-- <td> -->
								
								<!-- <div v-if="item.mySkuDto"> -->
									<!-- {{ item.mySkuDto.goodsName }} -->
									<!-- 已审核-No-不是新品 -->
									<!-- <span v-if="'10'==item.mySkuDto.status">NO</span> -->
									<!-- 未审核-YES-是新品 -->
									<!-- <span v-else-if="'0'==item.mySkuDto.status" style="font-weight: bold;">New</span> -->
								<!-- </div> -->
							<!-- </td> -->
							<!-- <td>{{item.marksNos }}</td> -->
							<!-- <td></td> -->
							<td>
								<div v-if="'0'==item.mySkuDto.status">
									<strong style="display: inline-block;border: 1px solid #333;padding: 0 5px;font-size: 12px;">New</strong>
								</div>
								<div><strong>{{ item.goodsSku }}</strong></div>
								<div v-if="item.mySkuDto" style="max-height:42pt; overflow:hidden;">
									{{ item.mySkuDto.goodsName }}
								</div>
							</td>
							<!-- <td>{{ item.packCount }}</td> -->
							<td>
								<div>
									<span>PCS</span>:
									<strong style="font-size: 12pt;">{{ item.packCount }}</strong>
								</div>
								<div>
									<span>CTN</span>:
									<strong style="font-size: 12pt;">{{item.totalCTN}}</strong>
								</div>
								
								<div style="border: 1px solid #333;">
									<span>每箱件数</span>:
									<strong>{{item.ctnPCS}}</strong>
								</div>
							</td>
							<!-- <td>{{item.ctnPCS}}</td> -->
							<td>&nbsp;</td>
							<td>
								<div v-if="item.mySkuDto">
									<div>
										{{ item.mySkuDto.goodsLength }}*{{ item.mySkuDto.goodsWidth }}*{{ item.mySkuDto.goodsHeight }}
										&nbsp;
										<span v-if="item.mySkuDto.sizeUnitName && item.mySkuDto.sizeUnitName.split('/')[1]">
											{{item.mySkuDto.sizeUnitName.split('/')[1]}}
										</span>
									</div>
									<div>
										{{ item.mySkuDto.goodsWeight }}&nbsp;
										<span v-if="item.mySkuDto.sizeUnitName && item.mySkuDto.sizeUnitName.split('/')[0]">
											{{item.mySkuDto.sizeUnitName.split("/")[0]}}
										</span>
									</div>
								</div>

							</td>
							<!-- <td>
								<span v-if="item.mySkuDto">
									{{ item.mySkuDto.goodsWeight }}&nbsp;
									<span v-if="item.mySkuDto.sizeUnitName && item.mySkuDto.sizeUnitName.split('/')[0]">
										{{item.mySkuDto.sizeUnitName.split("/")[0]}}
									</span>
								</span>
							</td> -->
							<td>{{ item.totalPallet }}</td>
							<td>
								<!-- <span v-for="(item2,index2) in item.skuPlace" :key="index2">{{item2.place}},</span> -->
								<span v-if="item.skuPlace && item.skuPlace.length>0">
									{{item.skuPlace.map(item2=>item2.place).join(',')}}
								</span>
							</td>
							<!-- <td>{{ item.remark }}</td> -->
						</tr>
					</tbody>
					<!-- <tbody  class="text_center">
						<tr>
							<th></th>
							<th>Total</th>
							
							<th>{{priterData.totalPcs}}</th>
							<th>{{priterData.totalCtn}}</th>
							<th></th>
							<th></th>
							<th>{{priterData.totalPallet}}</th>
							<th></th>
						</tr>
					</tbody> -->
					<tbody>
						<tr>
							<td colspan="8">
								<span style="padding: 0 2mm;">Live Unload?</span>
								<strong style="padding: 0 4mm;">Yes</strong>
								<strong style="padding: 0 4mm;">No</strong>
							</td>
						</tr>
					</tbody>
					<tbody>
						<tr>
							<th colspan="8">HYTX Logistics Unloading Procedure</th>
						</tr>
						<tr>
							<td colspan="8">
								1.Always have 4 pictures of the container<br />
								(1) Container Door Close shows container #<br />
								(2) Container Receipt<br />
								(3) Container Seal Lock<br />
								(4) Container Door Open<br />
							</td>
						</tr>
						<tr>
							<td colspan="8">
								2.Always put Pallet # label on EVERY PALLETS
							</td>
						</tr>
						<tr>
							<td colspan="8">
								3.If you see any damage during unloading,report immediately
							</td>
						</tr>
						<tr>
							<td colspan="8">
								4.Always sign and date
							</td>
						</tr>
						<tr>
							<td colspan="8">
								5.Always count
							</td>
						</tr>
					</tbody>
					<tbody>
						<tr>
							<!-- <td>Remark</td>
							<th colspan="7">
								{{priterData.remark}}
							</th> -->

							<td colspan="8">
								<span>Remark#&nbsp;</span>
								<strong class="pre-text">{{priterData.remark}}</strong>
							</td>

						</tr>
						<!-- <tr>
							<td colspan="8">
								{{priterData.remark}}
							</td>
						</tr> -->
					</tbody>
				</table>

				<div class="tableFooter">
					<ul>
						<li>Unloaded Date</li>
						<li>Unloaded By</li>
						<li>Counted By</li>
					</ul>
				</div>




				<!-- <table class="simTable printerTable">
          <tr>
            <th width="50">{{$t('7b1c2b1adc920d9c')}}</th>
            <th width="200">SKU</th>
            <th width="100">{{$t('b9d4d3f6183bf3c1')}}</th>
            <th width="200">SKU名称</th>
            <th width="100">重量/体积单位</th>
            <th width="100">{{$t('96f1a4b17e75892d')}}</th>

            <th width="100">长*宽*高</th>

            <th width="100">{{$t('c9fbcb0263c9b1f2')}}</th>
          </tr>
          <tbody v-if="priterData.records">
            <tr v-for="(item, index) in priterData.records" :key="index" style="">
              <td>{{ index + 1 }}</td>
              <td>{{ item.goodsSku }}</td>
              <td>{{ item.packCount }}</td>
              <td>{{ item.goodsNameEn }}<br />{{ item.goodsName }}</td>
              <td>{{ item.sizeUnitName }}</td>
              <td>{{ item.goodsWeight }}</td>

              <td>{{ item.goodsLength }}*<br />{{ item.goodsWidth }}*<br />{{ item.goodsHeight }}</td>
              <th>{{item.verifyCountTotal}}</th>
            </tr>
          </tbody>
        </table> -->
			</div>

			<!-- </div> -->
			<!-- </el-card> -->
		</div>

		<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button></div> -->
		<!-- </el-dialog> -->
		<!-- 增值服务 -->
		
		<div style="height: 10px;" class="no_print"></div>
		<div v-show="isShowValueAdded" v-if="priterData.workOrderDtos && priterData.workOrderDtos.length>0">
			<div class="printer_break"></div>
			<whPrinterValueAddListSub :openTime="ValueAddOpenTime" :tableData="priterData.workOrderDtos">
			</whPrinterValueAddListSub>
		</div>



	</div>
</template>
<script>
	// import Vue from 'vue';
	// import Print from '@/plugins/print.js';
	// Vue.use(Print); // 注册
	import barcode from '@/components/Common/barcode.vue';

	import whPrinterValueAddListSub from '@/printer/whPrinterValueAddListSub.vue';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		metaInfo() {
		  return {
		    title: this.priterData&&this.priterData.packingNo?this.priterData.packingNo+'-Container-Packing-List':'HYTX-Container-Packing-List',
		  }
		},
		// props: ['isDialog'],
		props: {
			isShowValueAdded: {
				type: Boolean
			},
			// mobile:"",
			// isShow: {
			//   default: function() {
			//     return false;
			//   },
			//   type: Boolean
			// },
			// openTime: {
			//   // default: function() {
			//   //   return '';
			//   // },
			//   // type: String
			// },
			// PrinterData: {
			//   default: function() {
			//     return [];
			//   },
			//   type: Array
			// }
		},
		components: {
			barcode,
			whPrinterValueAddListSub
		},
		data() {
			return {
				// dialogFile: false,
				// loadingUpload: false,
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// saveErr: false,
				filterData: {
					packId: ''
				},
				// listUrl: [],
				loading_load: false,
				priterData: {},
				ValueAddOpenTime: ''
			};
		},
		// watch: {
		//   $route(to, from) {
		//     if (this.$route.query && this.$route.query.packId) {
		//       this.filterData.packId = this.$route.query.packId;
		//     }
		//     this.initData();
		//   }
		// },
		//创建时
		created() {
			// if (this.isSel) {
			//   this.filterData.status = this.status;
			// }
			if (this.$route.query && this.$route.query.packId) {
				this.filterData.packId = this.$route.query.packId;
			}
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.getPageData();
			},
			//打印
			// printerAction() {
			//   window.print();
			//   // this.$print(this.$refs.print);
			// },
			//请求分页数据
			getPageData() {
				this.loading_load = true;

				this.$http
					.get(this.$urlConfig.WhPrinterReceptionList + '/' + this.filterData.packId, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						// offset: 0, //当前页
						// limit: 500, //当前页显示数目
						// verifyStatus: this.filterData.status ? this.filterData.status : null,
						// plNo: this.filterData.plNo ? this.filterData.plNo : null,
						// packId: this.filterData.packId ? this.filterData.packId : null,
						// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
						// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					})
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);

						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.priterData = data.data.print;
							//增值服务
							if (this.priterData && this.priterData.workOrderDtos && this.priterData.workOrderDtos.length > 0) {
								this.$emit('ValAddUpdate', {
									val: this.priterData.workOrderDtos.length,
									show: true,
								});
							} else {
								this.$emit('ValAddUpdate', {
									val: 0,
									show: true,
								});
							}
							this.ValueAddOpenTime = new Date().getTime();

							//取消编辑时调用
							//缓存，待编辑的数据
							// this.tableDataCatch = data.rows;//直接赋值为浅拷贝 concat也是浅拷贝
							// this.tableDataCatch = deepClone(data.rows);
							//当前数据总条数
							// this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('订单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>
	@import url(../assets/css/printer.less);
</style>